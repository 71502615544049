
.page-loading-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba($color: #ffffff, $alpha: 0.5) !important;
    z-index: 1000 !important;
    
    .custom-spinner {
        width: 50px !important;
        height: 50px !important;
        color: rgb(26, 11, 59) !important;
    }
}