.searchTextField {
    position: absolute;
    top: 1px;
    right: 2px;
    height: 94%;
    display: flex;
    align-items: center;
    border-left: 1px solid #dadada;
    width: 39px;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-radius: 0px 5px 5px 0px;

    svg {
        font-size: 16px;
    }

    &:hover {
        svg {
            font-size: 18px;
            color: #1378a7;
        }
    }
}