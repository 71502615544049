.custom-captcha {
    position: relative;
    padding: 4px 8px;
    width: 130px;
    margin-top: 18px;
    background-color: #f0f0f0;

    .cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(1px);
    }

    i {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        padding: 3px;
    }
}