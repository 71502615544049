.status {
  &-1 {
    color: white;
    background-color: #ff9900;
    border-radius: 10px;
    padding: 3px 5px;
    font-size: 12px;
  }

  // &-2 {
  //   color: white;
  //   background-color: #009999;
  //   border-radius: 10px;
  //   padding: 3px 5px;
  //   font-size: 12px;
  // }

  &-3 {
    color: white;
    background-color: #d7d7d7;
    border-radius: 10px;
    padding: 3px 5px;
    font-size: 12px;
  }

  &-4 {
    color: white;
    background-color: #009999;
    border-radius: 10px;
    padding: 3px 5px;
    font-size: 12px;
  }
}