.table-responsive {
  height: 100%;

  table {
      // height: 100%;

      tbody {
          height: calc(100% - 30px);
          overflow-y: auto;
      }
  }
}

.customs-collapse-row {

  .table-body {
      overflow-y: scroll;
      max-height: 400px;
  }

  td {
      border: 1px solid #ddd;
      --bs-border-opacity: 0.8;
  }

  .cell-action {
      //width: 30px;
      //max-width: 30px;
      //min-width: 30px;
      transition: all 0.1s ease-in;
      padding-left: 0.75rem !important;
      //border-right: 0;

  }

  .chevron-rotate-down {
      i:before {
          transform: rotate(90deg);
          transition: all 0.2s ease-in;
      }
  }

  .custom-children {
      td {
          padding: 10px;
      }

      td:nth-child(2) {
          border-left: 0;
      }
  }

  // .row-title {
  //     background: $color-dark-blur-1;

  //     td {
  //         padding: 8px;
  //     }

  //     td:nth-child(2) {
  //         border-left: 0;
  //     }
  // }

  .row-parent {
      td {
          --bs-border-opacity: 0.55;
      }
  }
}

table.dataTable {
  font-family:  Inter,Helvetica,"sans-serif";
  width: 100% !important;
  margin: 0 !important;

  th {
      border-bottom-color: #ddd;
  }
}

#kt_table_users {
  tr {
    &:hover {
      background-color: #E6FFFB;
    }
  }
}

.table::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.table::-webkit-scrollbar-thumb {
  background: #888; 
}


.bg-pri {
  background-color: #55a9ff !important;
  color: white;
  &:hover {
    background-color: #5ca5da;
  }
}

.table-header {
  position: relative;
  font-size: 13px;
  border: none;
  &::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    top: 50%;
    left: -9px;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.p-table {
  padding: 8px !important;
}

.table-row-dashed {
  tr {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ddd;
  }

  tfoot {
    th, td {
      border-top-width: 1px !important;
    }
  }
}
