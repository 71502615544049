body {
    font-family: 'Roboto',  sans-serif;
}


.ac-is-invalid {
  >div {
    --bs-border-opacity: 1;
    border-color: #F64E60 !important;
  }
}

.container-wrapper{
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinners {
    -moz-appearance: textfield;
}

.scroll-modal {
  max-height: calc(100vh - 180px);
  overflow-y: scroll;
}

.w {
  &-1200-px {
    width: 1200px !important;
  }
}

.header-dialog>h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px !important;
  text-transform: uppercase;
}

.z-index {
  &-1 {
    z-index: 1;
  }

  &-2 {
    z-index: 2;
  }

  &-3 {
    z-index: 3;
  }

  &-4 {
    z-index: 4;
  }

  &-5 {
    z-index: 5;
  }
  
  &-6 {
    z-index: 6;
  }

  &-7 {
    z-index: 7;
  }

  &-8 {
    z-index: 8;
  }

  &-9 {
    z-index: 9;
  }

  &-10 {
    z-index: 10;
  }
}

.css-tlfecz-indicatorContainer {
  padding: 1px !important;
}

.hidden-row {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}